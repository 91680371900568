import { useContext } from 'react';
import { SiteContext } from '../../contexts/Site/SiteContext';

export const AudioInfo = ({
  songName,
  isPlaying,
  textColor = 'red',
  backgroundColor = 'white',
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { isMobileView } = useContext(SiteContext);

  return (
    <div
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={
        isMobileView ? 'audio-info-container mobile' : 'audio-info-container'
      }
      style={{ color: textColor, backgroundColor }}
    >
      {isPlaying ? (
        <span>Listening to {`\u201C${songName}\u201D`} by Nett</span>
      ) : (
        <span>Listen to Nett</span>
      )}
    </div>
  );
};
