import { useContext } from 'react';
import { SiteContext } from '../../contexts/Site/SiteContext';
import { ModalLink } from './ModalLink';

export const NettInfo = () => {
  const bandcampLink = 'https://nettnyc.bandcamp.com';
  const spotifyLink = 'https://spotify.com';
  const {
    colorTheme: { accentColor },
  } = useContext(SiteContext);

  const instagramLink = 'https://instagram.com/nett.nyc';
  const mailtoLink = 'mailto:booking@nett.nyc';
  const eventLink = "https://www.eventbrite.com/e/solstice-tickets-909160111367"
  const videoLink = "https://www.youtube.com/watch?v=vB262pVvh9M"
  return (
    <div>
      <p>Nett is an experimental rock band based in NYC & Brooklyn.</p>

      <div style={{ color: accentColor, marginTop: '15px' }}>
        <p>
          &#x279F;{' '}
          <ModalLink
            href={bandcampLink}
            color={'red'}
            text={'Bandcamp'}
            includeIcon={false}
          />
        </p>
        <p>
          &#x279F;{' '}
          <ModalLink
            href={instagramLink}
            color={'red'}
            text={'Instagram'}
            includeIcon={false}
          />
        </p>
        <p>
          &#x279F;{' '}
          <ModalLink
            href={mailtoLink}
            color={'red'}
            text="Email"
            icon="mail"
            includeIcon={false}
          />
        </p>
        <p>
          &#x279F;{' '}
          <ModalLink
            href={videoLink}
            color={'red'}
            text={'2064 video'}
            includeIcon={false}
          />
        </p>

      </div>
    </div>
  );
};
