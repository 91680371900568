import { ModalLink } from "./ModalLink";

const showsStub = [
  {
    date: '29 June',
    venue: 'Living Gallery',
    ticketLink: 'https://www.eventbrite.com/e/solstice-tickets-909160111367',
    city: 'Brooklyn, NY',
  }
];
export const ShowsInfo = () => {
  return showsStub.map((show) => {
    const { date, venue, ticketLink, city} = show;
    return (
      <div className="shows-info" key={date + Math.floor(Math.random() * 12345)}>
        <div>{date}</div>
        <div>{venue}</div>
        <div>{city}</div>
        <div>
            <ModalLink href={ticketLink} text="Tickets" includeIcon={true} color="red" />
        </div>
      </div>
    );
  });
};
