import { createContext } from 'react';
import { lightColorTheme, menus, nettBackgroundSrc } from './constants';

const noOp = () => {};

const defaultValue = {
  colorTheme: lightColorTheme,
  setColorTheme: noOp,
  backgroundImageSrc: nettBackgroundSrc,
  currentMenu: menus.info,
  setCurrentMenu: noOp,
  hoveredMenu: menus.info,
  setHoveredMenu: noOp,
  isMobileView: false,
  isHeaderVisible: false,
  setIsHeaderVisible: noOp,
  siteWidth: 0,
};

export const SiteContext = createContext(defaultValue);
