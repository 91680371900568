import { useContext, useMemo } from 'react';
import { SiteContext } from '../../contexts/Site/SiteContext';
import { menus } from '../../contexts/Site';
import './Navigation.css';
import { Icon } from '../icons/Icon';

export const NavigationButton = ({ onClick, menuItem }) => {
  const {
    currentMenu,
    colorTheme: { textColor, accentColor, backgroundColor },
    setHoveredMenu,
    hoveredMenu,
    isMobileView,
    isHeaderVisible,
  } = useContext(SiteContext);
  const isActive = useMemo(() => {
    return currentMenu === menuItem || hoveredMenu === menuItem;
  }, [currentMenu, hoveredMenu]);

  const styles = {
    color: isActive ? accentColor : textColor,
  };

  const logoColor = isActive || isHeaderVisible ? textColor : accentColor;

  const onMouseEnter = () => {
    if (isMobileView) return;
    setHoveredMenu(menuItem);
  };

  const onMouseLeave = () => {
    if (isMobileView) return;
    setHoveredMenu(null);
  };

  const iconColor = isHeaderVisible
    ? isActive
      ? accentColor
      : textColor
    : isActive
      ? backgroundColor
      : accentColor;
  const iconBackgroundColor = isHeaderVisible
    ? backgroundColor
    : isActive
      ? accentColor
      : backgroundColor;
  const iconBorderColor = isHeaderVisible
    ? isActive
      ? accentColor
      : textColor
    : isActive
      ? accentColor
      : backgroundColor;

  return (
    <span
      style={styles}
      className="navigation-item"
      onClick={() => onClick(menuItem)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Icon
        type={menuItem}
        color={iconColor}
        backgroundColor={iconBackgroundColor}
        borderColor={iconBorderColor}
      />
    </span>
  );
};
