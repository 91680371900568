import { SiteContext } from '../../contexts/Site/SiteContext';
import { InfoModal } from '../info-modal';
import { LazyImage } from '../lazy-image';
import './Background.css';

import background from '../../images/nett-background.png';
import ancientSlate from '../../images/ancient-slate.png';
import { useEffect, useRef, useState } from 'react';

const randomIndex = Math.floor(Math.random() * 2);
export const Background = () => {
  const backgroundRef = useRef(null);

  const [containerHeight, setcontainerHeight] = useState(0);
  const [containerWidth, setcontainerWidth] = useState(0);

  const backgrounds = [background, ancientSlate];

  useEffect(() => {
    const updateSize = () => {
      const backgroundDiv = backgroundRef.current;
      const { height, width } = backgroundDiv.getBoundingClientRect();
      setcontainerHeight(height);
      setcontainerWidth(width);
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, [setcontainerHeight, setcontainerWidth, backgroundRef]);

  return (
    <div className="background" ref={backgroundRef}>
      <InfoModal />
      <LazyImage
        src={backgrounds[randomIndex]}
        height={containerHeight}
        width={containerWidth}
      />
    </div>
  );
};
