import { useCallback, useContext } from 'react';
import { SiteContext } from '../../contexts/Site/SiteContext';
import './NettLogo.css';
export const NettLogo = () => {
  const {
    colorTheme: { textColor, accentColor },
    setCurrentMenu,
    isHeaderVisible,
  } = useContext(SiteContext);

  const mainTextStyle = {
    color: textColor,
  };

  const subTextStyle = {
    color: accentColor,
  };

  const onClick = useCallback(() => {
    setCurrentMenu(null);
  }, [setCurrentMenu]);

  return (
    <div
      className={
        isHeaderVisible
          ? 'nett-logo-container visible'
          : 'nett-logo-container visible'
      }
      onClick={onClick}
    >
      <span style={mainTextStyle} className="nett-logo-main-text">
        nett
      </span>
      {isHeaderVisible && (
        <span style={subTextStyle} className="nett-logo-sub-text">
          (nyc)
        </span>
      )}
    </div>
  );
};
