import './Icons.css';

export const Contact = ({ size, color }) => {
  const style = {
    color,
  };

  return (
    <span className="contact-icon" style={style}>
      m
    </span>
  );
};
