import { useContext } from 'react';
import { ModalLink } from './ModalLink';
import { SiteContext } from '../../contexts/Site/SiteContext';

const instagramLink = 'https://instagram.com/nett.nyc';
const mailtoLink = 'mailto:booking@nett.nyc';
export const ContactInfo = () => {
  const {
    colorTheme: { accentColor },
  } = useContext(SiteContext);
  return (
    <div>
      <p>
        Send an{' '}
        <ModalLink
          href={mailtoLink}
          color={accentColor}
          text="email"
          icon="mail"
        />
      </p>
      <p>
        Follow on{' '}
        <ModalLink href={instagramLink} color={accentColor} text="Instagram" />
      </p>
    </div>
  );
};
