import './App.css';
import './Fonts.css';
import Background from './components/background';
import Footer from './components/footer';
import Header from './components/header';
import { SiteContextProvider } from './contexts/Site';

function App() {
  return (
    <div className="App">
      <SiteContextProvider>
        <Header />
        <Background />
        <Footer />
      </SiteContextProvider>
    </div>
  );
}

export default App;
