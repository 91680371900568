export const Pause = ({ color }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 512 640"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 640H192V0H0V640ZM320 0V640H512V0H320Z" fill={color} />
  </svg>
);
