import { useRef, useState, useCallback, useContext, useEffect } from 'react';
import WaveForm from './WaveForm';
import { Icon } from '../icons/Icon';
import './AudioPlayer.css';
import { SiteContext } from '../../contexts/Site/SiteContext';
import { AudioInfo } from './AudioInfo';

const audioUrl = '/audio/2064.mp3';

const songName = '2064';
export default function AudioPlayer({ width, height }) {
  const {
    isMobileView,
    colorTheme: { accentColor, backgroundColor },
  } = useContext(SiteContext);
  const [analyzerData, setAnalyzerData] = useState(null);
  const audioElmRef = useRef(null);
  const sourceRef = useRef(null);

  const audioCtxRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const audioAnalyzer = useCallback(() => {
    const analyzer = audioCtxRef.current.createAnalyser();
    analyzer.fftSize = 2048;
    const bufferLength = analyzer.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    if (sourceRef.current) {
      return;
    } else {
      sourceRef.current = audioCtxRef.current.createMediaElementSource(
        audioElmRef.current
      );
      sourceRef.current.connect(audioCtxRef.current.destination);
    }
    sourceRef.current.connect(analyzer);

    sourceRef.current.mediaElement.onended = () => {
      sourceRef.current.disconnect();
      sourceRef.current = null;
      setIsPlaying(false);
    };

    setAnalyzerData({ analyzer, bufferLength, dataArray });
  }, [setAnalyzerData, setIsPlaying]);

  const toggleAudio = useCallback(() => {
    if (audioElmRef.current) {
      if (audioElmRef.current.paused) {
        if (!audioCtxRef.current) {
          audioCtxRef.current = new (window.AudioContext ||
            window.webkitAudioContext)();
        }
        audioAnalyzer();
        audioElmRef.current.play();
        setIsPlaying(true);
        setIsHovering(false);
      } else {
        audioElmRef.current.pause();
        setIsPlaying(false);
      }
    }
  }, [audioElmRef]);

  const [isHovering, setIsHovering] = useState(false);

  const onMouseEnter = () => {
    if (isMobileView || isPlaying) return;
    setIsHovering(true);
  };

  const handleKeyPress = useCallback((e) => {
    console.log(e)
    if (e.key === " ") {
      toggleAudio();
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    }
  })

  const onMouseLeave = () => {
    if (isMobileView) return;
    setIsHovering(false);
  };

  const iconColor = isHovering && !isMobileView ? backgroundColor : accentColor;
  const iconBackgroundColor = isHovering ? accentColor : backgroundColor;
  const iconBorderColor = isHovering ? accentColor : backgroundColor;

  return (
    <>
      {!isPlaying ? (
        <div
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className="media-button"
          onClick={toggleAudio}
        >
          <Icon
            type="play"
            color={iconColor}
            backgroundColor={iconBackgroundColor}
            borderColor={iconBorderColor}
          />
        </div>
      ) : (
        <div className="media-button" onClick={toggleAudio}>
          <Icon
            type="pause"
            color="red"
            backgroundColor={'white'}
            borderColor={'white'}
          />
        </div>
      )}
      <audio
        style={{ display: 'none' }}
        ref={audioElmRef}
        src={audioUrl}
        controls
      />
      {analyzerData && (
        <WaveForm analyzerData={analyzerData} width={width} height={height} />
      )}
      <AudioInfo
        isPlaying={isPlaying}
        songName={songName}
        onClick={toggleAudio}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        textColor={iconColor}
        backgroundColor={iconBackgroundColor}
      />
    </>
  );
}
