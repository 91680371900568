import { useContext } from 'react';
import './InfoModal.css';
import { NettInfo } from './NettInfo';
import { SiteContext } from '../../contexts/Site/SiteContext';
import { menus } from '../../contexts/Site';
import { ContactInfo } from './ContactInfo';
import { ShowsInfo } from './ShowsInfo';

export const InfoModal = () => {
  const { currentMenu, isMobileView, colorTheme } = useContext(SiteContext);

  const MenuComponent = () => {
    if (currentMenu === menus.info) return <NettInfo />;
    else if (currentMenu === menus.contact) return <ContactInfo />;
    else if (currentMenu === menus.shows) return <ShowsInfo />;
    else return null;
  };

  const style = {
    backgroundColor: colorTheme.backgroundColor,
    color: colorTheme.textColor,
  };
  return (
    currentMenu && (
      <div
        className={
          isMobileView ? 'info-modal-container mobile' : 'info-modal-container'
        }
        style={style}
      >
        <div className="info-modal-inner-container">
          <MenuComponent />
        </div>
      </div>
    )
  );
};
