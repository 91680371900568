export const Play = ({ color }) => {
  return (
    <span className="play-icon">
      <svg
        width="25"
        height="25"
        viewBox="0 0 512 640"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0L512 320L0 640V0Z" fill={color} />
      </svg>
    </span>
  );
};
