import { useContext } from 'react';
import { SiteContext } from '../../contexts/Site/SiteContext';
import AudioPlayer from '../audio-player';
import './Footer.css';

export const Footer = () => {
  const { siteWidth } = useContext(SiteContext);
  return (
    <footer>
      <AudioPlayer width={siteWidth} height={200} />
    </footer>
  );
};
