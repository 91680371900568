const coolGreen = '#10E30C';

export const lightColorTheme = {
  backgroundColor: 'white',
  borderColor: 'red',
  textColor: 'black',
  accentColor: 'red',
};

export const darkColorTheme = {
  backgroundColor: 'black',
  borderColor: '#EEF7F8',
  textColor: '#EEF7F8',
  accentColor: '#05FF00',
};

export const nettBackgroundSrc = 'http://localhost:3000/nett-background.png';

export const menus = {
  info: 'about',
  contact: 'contact',
  shows: 'shows',
};

export const mobileBreakpoint = 500;
